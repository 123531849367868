var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "orderTest-container" }, [
    _c(
      "div",
      { staticClass: "f" },
      [
        _c("el-date-picker", {
          attrs: {
            type: "year",
            placeholder: "选择年份",
            format: "yyyy 年",
            "value-format": "yyyy",
          },
          model: {
            value: _vm.time,
            callback: function ($$v) {
              _vm.time = $$v
            },
            expression: "time",
          },
        }),
        _c(
          "el-button",
          {
            staticStyle: { "margin-left": "10px" },
            attrs: { type: "primary" },
            on: { click: _vm.fetchData },
          },
          [_vm._v(" 查询 ")]
        ),
        _c("div", { staticStyle: { "margin-left": "50px" } }, [
          _vm._v(
            " 提示：结转后该月不可作废或新增凭证，未审核的费用单据将不可审核，反结转后方可操作 "
          ),
        ]),
      ],
      1
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        staticClass: "content",
      },
      _vm._l(_vm.list, function (item, index) {
        return _c("el-card", { key: index, staticClass: "el-card" }, [
          item.status != 0
            ? _c("div", { staticClass: "status" }, [
                item.status == 1
                  ? _c("div", { staticStyle: { color: "#67c23a" } }, [
                      _vm._v("已结转"),
                    ])
                  : _vm._e(),
                item.status == 2
                  ? _c("div", { staticStyle: { color: "#f56c6c" } }, [
                      _vm._v("未结转"),
                    ])
                  : _vm._e(),
              ])
            : _vm._e(),
          _c("div", { staticClass: "info" }, [_vm._v(_vm._s(item.name))]),
          item.is_turn !== 0
            ? _c(
                "div",
                { staticClass: "btn" },
                [
                  item.is_turn == 1
                    ? _c(
                        "el-button",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.hadleJiezhuan(1)
                            },
                          },
                        },
                        [_vm._v(" 反结转 ")]
                      )
                    : _vm._e(),
                  item.is_turn == 2
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.hadleJiezhuan(0)
                            },
                          },
                        },
                        [_vm._v(" 结转 ")]
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ])
      }),
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }