<template>
  <!-- NAME[epic=财务] 结转 -->
  <div class="orderTest-container">
    <div class="f">
      <el-date-picker
        v-model="time"
        type="year"
        placeholder="选择年份"
        format="yyyy 年"
        value-format="yyyy"
      ></el-date-picker>
      <el-button type="primary" style="margin-left: 10px" @click="fetchData">
        查询
      </el-button>
      <div style="margin-left: 50px">
        提示：结转后该月不可作废或新增凭证，未审核的费用单据将不可审核，反结转后方可操作
      </div>
    </div>
    <div v-loading="loading" class="content">
      <el-card v-for="(item, index) in list" :key="index" class="el-card">
        <div v-if="item.status != 0" class="status">
          <!-- 0未结转显示为空1已结转文字2未结转文字 -->
          <!-- {{ item.status == 1 ? '已结转' : '未结转' }} -->
          <div v-if="item.status == 1" style="color: #67c23a">已结转</div>
          <div v-if="item.status == 2" style="color: #f56c6c">未结转</div>
        </div>
        <div class="info">{{ item.name }}</div>
        <!--  is_turn 0不可反结转,显示空，1反结转按钮，2结转按钮 -->
        <div v-if="item.is_turn !== 0" class="btn">
          <el-button v-if="item.is_turn == 1" @click="hadleJiezhuan(1)">
            反结转
          </el-button>
          <el-button
            v-if="item.is_turn == 2"
            type="primary"
            @click="hadleJiezhuan(0)"
          >
            结转
          </el-button>
        </div>
      </el-card>
    </div>
  </div>
</template>
<script>
  import { postAction } from '@/api/Employee'

  export default {
    name: 'JieZhuan',
    components: {},
    data() {
      return {
        time: '',
        list: [],
        loading: false,
      }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
      this.fetchData()
    },
    methods: {
      async fetchData() {
        this.loading = true
        try {
          let res = await postAction('/cashAdmin/subject-detail/turn-list', {
            year: this.time,
          })
          this.list = res.data
          this.total = Number(res.totalCount) || 0
          this.loading = false
        } catch (err) {
          console.log('接口调用错误', err)
          this.loading = false
        }
      },
      hadleJiezhuan(type) {
        // 0结转1反结转
        let str = type == 0 ? '确定要结转此项吗?' : '确定要反结转此项吗'
        this.$confirm(str, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(async () => {
            this.loading = true
            // 0结转1反结转
            let res = await postAction('/cashAdmin/subject-detail/turn-set', {
              type,
            })
            this.$message.success(res.msg)
            this.fetchData()
            this.loading = false
          })
          .catch(() => {
            console.log('no')
          })
      },
    },
  }
</script>
<style lang="scss" scoped>
  .f {
    display: flex;
    align-items: center;
  }
  .content {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    position: relative;
    overflow: hidden;
    .el-card {
      width: 15%;
      margin: 10px;
      box-sizing: border-box;
      position: relative;
      overflow: hidden;
      .info {
        width: 100%;
        font-size: 26px;
        font-weight: 600;
        line-height: 7.5;
        box-sizing: border-box;
        overflow: hidden;
      }
      .status {
        text-align: right;
        right: 20px;
      }
      .status,
      .btn {
        position: absolute;
      }
      .btn {
        width: 100%;
        left: 0px;
        bottom: 20px;
        margin: 0 auto;
        text-align: center;
      }
    }
  }
</style>
